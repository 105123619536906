import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Paper } from "@mui/material";

const dateParams = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

const timeZone = { timeZone: "America/Los_Angeles" };

/**
 * Footer component for App.
 * @param {*} props
 * @returns {JSX} JSX reprsentation of footer component.
 */
const AppFooter = (props) => {
  const [footerDate, setFooterDate] = useState(
    new Date().toLocaleString("en-US", dateParams, timeZone)
  );

  const updateTime = () => {
    setFooterDate(new Date().toLocaleString("en-US", dateParams, timeZone));
  };

  useEffect(() => {
    setFooterDate(new Date().toLocaleString("en-US", dateParams, timeZone));
    setInterval(updateTime, 2000);
  }, []);

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
      }}
    >
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"For any Issues, Questions or  Feature requests, Please reach out to "}
        <Link color="inherit" href="mailto:dv-live-sys@amazon.com">
          {"dv-live-sys@amazon.com"}
        </Link>
        {"."}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {footerDate}
        {"  PDT"}
      </Typography>
    </Paper>
  );
};

export default AppFooter;
