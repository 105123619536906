import React from "react";
import ReactDOM from "react-dom/client";
import { StyledEngineProvider } from "@mui/material/styles";
import { Amplify } from "aws-amplify";
import { GlobalContextProvider } from "./contexts/GlobalContextStore";

import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from "./contexts/AuthProvider";
import { awsConfig } from "./config/awsConfig";

Amplify.configure(awsConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <AuthContextProvider>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </AuthContextProvider>
    </GlobalContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
