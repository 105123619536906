import * as React from "react";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { AuthContext } from "../contexts/AuthProvider";

export const ExpiringAlert = (props) => {
    const [isVisible, setIsVisible] = React.useState(true);
    const [AuthState, AuthDispatch] = React.useContext(AuthContext);

    const setTimer = (delay) =>
    {
        setTimeout(() => onAlertClose(), delay);
    };

    React.useEffect(() =>
    {
        setTimer(props.alertDelay);
    }, []);

    const onAlertClose = () => {
        let token = null;
        let user = null;
        let expiry = 0;

        AuthDispatch({
            type: "LOGOUT",
            payload: {
                user,
                token,
                expiry
            },
        });

        setIsVisible(false)
    }


    return (
        isVisible
            ? <React.Fragment>
                <Box sx={{ flexGrow: 1 }}>
                    <Alert m={2} severity="error">
                        {props.alertMessage}
                    </Alert>
                </Box>
            </React.Fragment>
            : <span />
    );
}

