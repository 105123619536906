import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
// import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
// import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
// import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CircleIcon from "@mui/icons-material/Circle";
// import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
// import MenuIcon from "@mui/icons-material/Menu";
// import DirectionsIcon from "@mui/icons-material/Directions";
// import SchemaIcon from "@mui/icons-material/Schema";
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import { Avatar, FormControl, IconButton, Stack, TextField } from "@mui/material";

// Local imports
import { nodeColorObject } from "../js/miscUtils";
// import cytoImage from "./cytoScapeLogo.png";
import { GlobalContext } from "../contexts/GlobalContextStore";

const useStyles = makeStyles({
    root: {
        width: 3500,
    },
    underline: {
        color: "red",

        "&::after": {
            // border: "2px solid red",
            width: "0%",
        },
        "&::before": {
            // border: "2px solid red",
            width: "50%",
        },
    },
});

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
}));

export default function GraphToolBar() {
    const [storeStates, storeDispatch] = React.useContext(GlobalContext);
    const [showClearIcon, setShowClearIcon] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState("");
    const [submit, setSubmit] = React.useState(false);
    const searchInput = React.useRef(null);
    const classes = useStyles();

    const handleChange = (event) => {
        event.preventDefault();
        setSubmit(false);
        setShowClearIcon(event.target.value === "" ? false : true);
        setSearchValue(event.target.value);
    };

    const handleSearchEnterPressed = (e) => {
        // e.preventDefault();
        if (e.key === "Enter") {
            e.preventDefault();
            handleOnClick(e);
        }
    };

    const handleClearIconClick = (event) => {
        event.preventDefault();
        // console.log("clicked the clear icon...");
        // Send search data to global context to be sent to graph component
        // TBD here
        searchInput.current.value = "";
        setShowClearIcon(false);
    };

    const handleOnClick = (event) => {
        event.preventDefault();
        // console.log("in handleOnClick");
        // Send search data to global context to be sent to graph component
        // TBD here
        // console.log(searchInput.current.value);
        searchInput.current.value = "";
    };

    const handleChipClick = (event) => {
        console.info(`You clicked the Chip. ${event.target.innerText}`);
    }

    return (
        <>
            <React.Fragment>
            <Stack spacing={1} direction="row" alignItems="center" sx={{  m: 1, mr: 0 }}>
                <Typography variant="h8" m={1} sx={{ align: "top" }}>
                    {"Node Types"}
                </Typography>

                <Divider sx={{ height: "4em", m: 0.5 }} orientation="vertical" />
            
                {
                    Object.keys(nodeColorObject).map((node) => (
                        // Enable click when node type based manipulation is implemented.
                        // <Chip key={node} label={node} variant="outlined"  sx={{ color: `${nodeColorObject[node]}`, fontWeight: 'bold', pointerEvents: 'none'}} onClick={handleChipClick} />
                        <Chip   key={node} label={node} variant="outlined"  onClick={handleChipClick} 
                                sx={{ color: 'white' , backgroundColor: `${nodeColorObject[node]}`, fontWeight: 'bold', pointerEvents: 'none'}}
                        />
                    ))
                }
                <Divider sx={{ height: "4em", m: 0.5 }} orientation="vertical" />
                <Paper  sx={{ p: 1, ml: 2, width: "20vw", maxWidth: "20rem" }}>
                    <Alert m={2} severity="info" icon={false} >
                        {storeStates.statusBarMsg}
                    </Alert>
                </Paper>
                {/* {console.log("{storeStates.statusBarMsg}", storeStates.statusBarMsg)} */}

            </Stack>
            </React.Fragment>
        </>
    );
}
