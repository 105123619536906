import * as React from "react";
import Box from "@mui/material/Box";

// Local import
import { AppHeader } from "../components/AppBar";
import { UseCase1 } from "../components/UseCase1";
import { UseCase2 } from "../components/UseCase2";
import { UseCase3 } from "../components/UseCase3";
import { UseCase4 } from "../components/UseCase4";

import { GlobalContext } from "../contexts/GlobalContextStore";
import { getDxList } from "../js/neptuneApiUtils";

/**
 * Dashboard component
 * @param {*} props
 * @returns {JSX} JSX object
 */

export default function Dashboard(props) {
    const [storeStates, storeDispatch] = React.useContext(GlobalContext);

    React.useEffect(() => {
        const getDxListProps = {
            action: "get_dx_list",
        };

        getDxList(getDxListProps).then((data) => {
            console.log("Dashboard:useEffect:getDxList:data ");
            console.log(data);
            storeDispatch({
                type: "LOAD_DX_LIST_DATA",
                dxListData: data,
            });
        });
    }, []);

    return (
        <React.Fragment>
            <AppHeader />
            <Box m={1}>
                <UseCase1 />
                {/* ====================================================== */}
                <UseCase2 />
                {/* ====================================================== */}
                <UseCase3 />
                {/* ====================================================== */}
                <UseCase4 />
                {/* ====================================================== */}
            </Box>
        </React.Fragment>
    );
}
