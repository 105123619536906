import React, { createContext, useReducer } from "react";

const initialState = {
    dxPassthroughComponentsByDependentNameData: [],
    elementsMapByPropertyNameData: [],
    dxListData: [],
    statusBarMsg: "",
    sessionToken: null,
};

const appReducer = (state, action) => {
    switch (action.type) {
        case "LOAD_DX_LIST_DATA":
            return {
                ...state,
                dxListData: action.dxListData,
            };

        case "LOAD_DX_PASSTHROUGH_COMPONENTS_BY_DEPENDENT_NAME":
            return {
                ...state,
                dxPassthroughComponentsByDependentNameData: action.dxPassthroughComponentsByDependentNameData,
            };
        case "LOAD_ELEMENTS_MAP_BY_PROPERTY_NAME":
            return {
                ...state,
                elementsMapByPropertyNameData: action.elementsMapByPropertyNameData,
            };
        
        case "LOAD_STATUS_BAR_MESSAGE":
            return {
                ...state,
                statusBarMsg: action.statusBarMsg,
            };
        
        case "SET_SESSION_TOKEN":
            return {
                ...state,
                sessionToken: action.sessionToken,
            };

        default:
            return state;
    }
};

//Create new context for global
const GlobalContext = createContext(undefined);

//Configure Context Provider with reducer function, initial global values and globalContext
const GlobalContextProvider = ({ children }) => {
    const globalContextValue = useReducer(appReducer, initialState);
    return <GlobalContext.Provider value={globalContextValue}>{children}</GlobalContext.Provider>;
};

export { GlobalContext, GlobalContextProvider };
