import { jwtVerifier } from "../config/awsConfig";

const nodeColorObject = {
    infra_group: "#34568B",
    cs_group: "#FF6F61",
    contribution_stream: "#6B5B95",
    feed: "#88B04B",
    packager_stream: "#9B2335",
    hw_encoder: "#5B5EA6",
    job: "#955251",
    dx: "#C3447A",
    multicast_sye: "#7e5ba6",
    delta_proxy: "#009B77",
    emp: "#DFCFBE",
    default: "#EFC050",
    // nex_node: "#5b83a6",
};

const verifyJwtToken = async (token) => {
    // Verify given token
    //  Boolean , returns true / false
    let payload;
    try {
         payload = await jwtVerifier.verify(token);
    } catch (e) {
        // console.warn(e)
        console.warn("token expired or can not be verified.")
        return false
    } 
    let expiry = payload.exp;

    if (expiry > parseInt(new Date() / 1000)){
        return true

    } else{ // token expired
        return false
    }
}



export { 
    nodeColorObject,
    verifyJwtToken,
};
