import React, { useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";

// local imports

import Dashboard from "./pages/Dashboard";
import { AuthContext } from "./contexts/AuthProvider";
import { GlobalContext } from "./contexts/GlobalContextStore";
import { Login } from "./components/Login";
import { NotFoundPage } from "./pages/InValidPath";
import { jwtVerifier } from "./config/awsConfig";
import {verifyJwtToken } from "./js/miscUtils"

const RedirectOnLogin = () => {
    const [storeStates, storeDispatch] = useContext(GlobalContext);
    const redirectData = JSON.parse(localStorage.getItem("url"));
    const navigate = useNavigate();

    useEffect(() => {
        storeDispatch({
            type: "SET_FROM_LOGIN_PAGE",
            fromLoginPage: false,
        });

        localStorage.setItem("url", null); //clear url data

        if (typeof redirectData === "object" && redirectData !== null && redirectData !== undefined) {
            navigate({
                pathname: redirectData.pathname,
                search: redirectData.search,
            });
        } else {
            navigate({
                pathname: "/",
                search: "",
            });
        }
    }, []);
    return (
        <>
            <h4> Redirecting .......</h4>
        </>
    );
};


function App() {
    const [AuthState, AuthDispatch] = useContext(AuthContext);
    const [storeStates, storeDispatch] = useContext(GlobalContext);

    const processLogout = () => {
        // localStorage.clear();
        let token = null;
        let user = null;
        let expiry = 0;

        storeDispatch({
            type: "SET_SESSION_TOKEN",
            sessionToken: token,
        });

        AuthDispatch({
            type: "LOGOUT",
            payload: {
                user,
                token,
                expiry
            },
        });
    };

    // For logging in using exsiting token
    React.useEffect(() => {
        let user = localStorage.getItem("user") || null;
        let token = localStorage.getItem("token") || null;
        let expiry = localStorage.getItem("expiry") || 0;

        // console.log("initial user=>" + user); //DEBUG AAM
        // console.log("initial token=>" + token); //DEBUG AAM
        // console.log("initial expiry=>" + expiry); //DEBUG AAM

        const processExistingToken = async (user, token, expiry) => {

            try {
                const payload = await jwtVerifier.verify(token);
                expiry = payload.exp;
                console.log("Existing Token valid, Sigin in progress");
                // console.log("Payload:", payload);
                // console.log("expiry:", expiry);

                

                if ( await verifyJwtToken(token) === true) {
                    storeDispatch({
                        type: "SET_SESSION_TOKEN",
                        sessionToken: token,
                    });

                    AuthDispatch({
                        type: "LOGIN",
                        payload: {
                            user,
                            token,
                            expiry,
                        },
                    });
                } else {
                    console.log("Existing Token expired, Signout in progress");
                    Auth.signOut();
                    processLogout(); 
                }
                
            } catch (e) {
                console.error("Error verifying existing JWT token");
                throw e;
            }
        };

        if (user && token) {
            //Check for existing token validity
            processExistingToken(user, token,expiry ).catch((e) => console.log("Error processing existing token: " + e));
        }

        //exit cleanup function
        return () => {
            console.log("Exiting App.");
            processLogout();
        };
    }, []);

    React.useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            console.dir(process.env);

            switch (event) {
                case "signIn":
                    console.log("signIn event in progress");
                    console.dir(data);
                    break;

                case "cognitoHostedUI":
                    console.log("In cognitoHostedUI");
                    console.dir(data);

                    Auth.currentSession().then((session) => {
                        console.dir(session);
                        let user = session.idToken.payload.identities[0].userId || null;
                        let token = session.accessToken.jwtToken || null;
                        // let expiry = 0;
                        // console.log("session user=>" + user); //DEBUG AAM
                        // console.log("session token=>" + token); //DEBUG AAM
                        
                        

                        const processNewToken = async (user, token) => {
                            // Check JWT validity
                            // ============== dev ===================
                            console.log("validating new token expiry")
                            console.log(" token valid : ", await verifyJwtToken(token))
                            //  ============ end dev =================

                            // Check JWT validity
                            try {
                                const payload = await jwtVerifier.verify(token);
                                let expiry = payload.exp
                                console.log("New Token valid, Sigin in progress");
                                // console.log("Payload:", payload);
                                // console.log("Expiry:", expiry);

                                if ( await verifyJwtToken(token) === true) {
                                    storeDispatch({
                                        type: "SET_FROM_LOGIN_PAGE",
                                        fromLoginPage: true,
                                    });

                                    storeDispatch({
                                        type: "SET_SESSION_TOKEN",
                                        sessionToken: token,
                                    });

                                    AuthDispatch({
                                        type: "LOGIN",
                                        payload: {
                                            user,
                                            token,
                                            expiry,
                                        },
                                    });
                                } else {
                                    console.log("New Token expired, Signout in progress");
                                    Auth.signOut();
                                    processLogout();
                                }
                            } catch  (e) {
                                console.error("New  JWT Token validity failed");
                               throw e;
                            }
                        };

                        if (user && token) {
                            //Check for existing token validity
                            processNewToken(user, token).catch((e) =>
                                console.log("Error processing existing token: " + e)
                            );
                        } else {
                            console.log("Error in new token: ");
                        }
                    });
                    break;

                case "signOut":
                    console.log("signOut event in progress");
                    processLogout();
                    break;

                case "oAuthSignOut":
                    console.log("oAuthSignOut event in progress");
                    console.dir(data);
                    processLogout();
                    break;

                case "signIn_failure":
                    console.log("signIn_failure event in progress");
                    console.dir(data);
                    processLogout();
                    break;

                case "cognitoHostedUI_failure":
                    console.log("cognitoHostedUI_failure event in progress");
                    console.dir(data);
                    processLogout();
                    break;

                default:
                    console.dir(data);
                    console.dir(event);
                    console.log("In Hub Default option.");
            }
        });
    }, []);

    return (
        <React.Fragment>
            {/* <Dashboard /> */}
            <BrowserRouter>
                {AuthState.isAuthenticated === false ? (
                    <Login />
                ) : (
                    <>
                        {storeStates.fromLoginPage && localStorage.getItem("url") !== null ? (
                            <RedirectOnLogin />
                        ) : (
                            <>
                                <Routes>
                                    <Route exact path="/" element={<Dashboard />} />
                                    {/* <Route path="params" element={<WebUrlParamsHandler />} /> */}
                                    <Route path="*" element={<NotFoundPage />} />
                                </Routes>
                            </>
                        )}
                    </>
                )}
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;
