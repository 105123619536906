import React, { useContext } from "react";
import { Auth } from "aws-amplify";
import { useLocation } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Button,
  CardActions,
  CardContent,
  Container,
  CssBaseline,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import AppFooter from "../components/AppFooter";
import DashboardTheme from "../js/DashboardTheme";
import { GlobalContext } from "../contexts/GlobalContextStore";

const LoginToolbar = () => {
  const [storeStates, storeDispatch] = useContext(GlobalContext);

  // Trigger toggle using onChange Switch
  const handleModeChange = () => {
    storeDispatch({
      type: "TOGGLE_DARK_THEME",
      toggleDark: !storeStates.toggleDark,
    });
  };

  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            align="center"
            noWrap
            sx={{
              flexGrow: 1,
              color: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.main
                  : theme.palette.primary.contrastText,
            }}
          >
            Cerebro Dashboard
          </Typography>
          <Typography mr={2}></Typography>
          {/* <Avatar alt="username" /> */}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export const Login = () => {
  const [storeStates, storeDispatch] = React.useContext(GlobalContext);
  const locationData = useLocation();

  const signIn = async (e) => {
    e.preventDefault();
    try {
      console.log("Logging in via AWS-Federate");
      // await Auth.federatedSignIn({provider: "AWS-Federate"});
      localStorage.setItem("url", JSON.stringify(locationData)); //save url data
      // await Auth.federatedSignIn();
      await Auth.federatedSignIn({provider: "Federate-Midway"});
    } catch (error) {
      console.log("error signing in", error);
    }
  };

  return (
    <ThemeProvider theme={DashboardTheme(storeStates.toggleDark)}>
      <Container>
        <CssBaseline />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh", color: "inherit" }}
        >
          <LoginToolbar />
          <CardContent>
            <Typography variant="h4" component="div" gutterBottom mb={4}>
              Please Login
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              sx={{ fontSize: "1.4rem" }}
              variant="outlined"
              onClick={signIn}
            >
              Login
            </Button>
          </CardActions>
          <AppFooter />
        </Grid>
      </Container>
    </ThemeProvider>
  );
};
