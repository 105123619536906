import React, { createContext, useReducer } from "react";

const AuthReducer = (AuthState, AuthAction) => {
  // console.log("AuthAction.payload.user=>" + AuthAction.payload.user); //DEBUG AAM
  // console.log("AuthAction.payload.token=>" + AuthAction.payload.token); //DEBUG AAM
  switch (AuthAction.type) {
    case "LOGIN":
      localStorage.setItem("user", AuthAction.payload.user);
      localStorage.setItem("token", AuthAction.payload.token);
      localStorage.setItem("expiry", AuthAction.payload.expiry);
      return {
        ...AuthState,
        isAuthenticated: true,
        // isTokenExpired: false,
        user: AuthAction.payload.user,
        token: AuthAction.payload.token,
        expiry: AuthAction.payload.exp,
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...AuthState,
        isAuthenticated: false,
        // isTokenExpired: true,
        user: AuthAction.payload.user,
        token: AuthAction.payload.token,
        expiry: AuthAction.payload.exp,
      };
    default:
      return AuthState;
  }
};

const AuthInitialState = {
  isAuthenticated: false,
  // isTokenExpired: true,
  user: null,
  token: null,
  expiry: 0,
};

// create Auth context
const AuthContext = createContext(null);

const AuthContextProvider = ({ children }) => {
  const AuthContextValue = useReducer(AuthReducer, AuthInitialState);

  return (
    <AuthContext.Provider value={AuthContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
