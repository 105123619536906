import * as React from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";


// Local imports
import { GlobalContext } from "../contexts/GlobalContextStore"
import { AccordianHeader} from "./AccordianHeader";
import {runMasterQuery} from "../js/neptuneApiUtils";
import {verifyJwtToken} from "../js/miscUtils"
import {ExpiringAlert} from "./ExpiringAlert";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  textAlign: "center",
  border: "none",
  color: theme.palette.text.secondary,
}));

const MenuComponents = [
  {
      value: "infra_group",
      label: "infra groups",
  },
  {
      value: "cs_group",
      label: "cs groups",
  },
  {
      value: "contribution_stream",
      label: "contribution streams",
  },
  {
      value: "feed",
      label: "feeds",
  },

  {
      value: "packager_stream",
      label: "packager streams",
  },
  {
      value: "hw_encoder",
      label: "hw encoders",
  },
  {
      value: "job",
      label: "jobs",
  },
  {
      value: "sye",
      label: "multicast-sye",
  },
  {
      value: "dx",
      label: "dx",
  },
  {
      value: "delta_proxy",
      label: "delta (HA) proxies",
  },
  {
      value: "emp",
      label: "emp",
  },
  {
      value: "csid",
      label: "csids",
  },
];

export const UseCase1 = () => {
  const initialValue = "";
  const [expanded, setExpanded] = React.useState(false);
  const [selectedSourceComponentType, setselectedSourceComponentType] = React.useState(initialValue);
  const [selectedSourceComponentName, setselectedSourceComponentName] = React.useState(initialValue);
  const [selectedTargetComponentType, setselectedTargetComponentType] = React.useState(initialValue);
  const [runQuery, setRunQuery] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [queryData, setQueryData] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [disableRunQueryIcon, setDisableRunQueryIcon] = React.useState(false);
  const [storeStates, storeDispatch] = React.useContext(GlobalContext);

  // load status bar messages
  const loadStatusBarMsg = (msg) => {
    storeDispatch({
        type: "LOAD_STATUS_BAR_MESSAGE",
        statusBarMsg: msg,
    });
  }

   
  React.useEffect(() => {
    if (runQuery) {
      console.log( `user has selected 
        selectedSourceComponentType : ${selectedSourceComponentType},selectedSourceComponentName : ${selectedSourceComponentName},selectedTargetComponentType : ${selectedTargetComponentType}
      `)

      //  Generate parameter object that will be passed to API
      const masterQueryAPIparams = {
        "query": "master_query",
        "source_component_type": selectedSourceComponentType,
        "source_component_name": selectedSourceComponentName,
        "target_component_type": selectedTargetComponentType
      }

        // Send query to lambda and get result back
        try {
            // getMasterQueryResult(masterQueryAPIparams).then((data) => {
            runMasterQuery(masterQueryAPIparams).then((data) => {
              console.log("Lambda: Master query result");
              console.log(data);
              setQueryData('response' in data && data['response'] ? data['response'].sort() : data);
              setIsLoading(false);
              // setselectedSourceComponentType(initialValue);
              // setselectedSourceComponentName(initialValue);
              // setselectedTargetComponentType(initialValue);
            })
            } catch (apiCallErr) {
              console.error("data missing or event timed out")
              setIsLoading(false);
              setShowAlert(true)
              setAlertMessage("Data Fetch API Timed Out")
              throw apiCallErr
        }
    }
  }, [runQuery]);


  const handleSourceComponentTypeSelection = (event) => {
    setRunQuery(false);
    // console.log("Selected Source Component Type:", event.target.value); //debug aam
    setselectedSourceComponentType(event.target.value);
    setDisableRunQueryIcon(false)
  };


  const handleSourceComponentNameSelection = (event) => {
    setRunQuery(false);
    // console.log("Selected Source Component Name:", event.target.value); //debug aam
    setselectedSourceComponentName(event.target.value);
    setDisableRunQueryIcon(false)
  };

  const handleTargetComponentTypeSelection = (event) => {
    setRunQuery(false);
    // console.log("Selected Target Component Type:", event.target.value); //debug aam
    setselectedTargetComponentType(event.target.value);
    setDisableRunQueryIcon(false)
  };

  const handleRunQuery = async (event) => {
    console.log("validating existing token expiry")
    let isSessionTokenValid = storeStates.sessionToken !== null ? await verifyJwtToken(storeStates.sessionToken) : false
    console.log(" token valid: ", isSessionTokenValid)
    if ( isSessionTokenValid === true){
      console.log("token good")
      setShowAlert(false)
      setRunQuery(selectedSourceComponentType && selectedSourceComponentName && selectedTargetComponentType ? true : false);
      setIsLoading(true);
      loadStatusBarMsg("")
      setDisableRunQueryIcon(selectedSourceComponentType && selectedSourceComponentName && selectedTargetComponentType  ? true : false)
  } else {
      // token invalid , logout
      console.log("token bad - logout")
      setAlertMessage("Token expired, redirecting to login page ....")
      setShowAlert(true)
      }
  };

  const clearPanelContents = () => {
    setselectedSourceComponentType(initialValue);
    setselectedSourceComponentName(initialValue);
    setselectedTargetComponentType(initialValue);
    setRunQuery(false);
    setIsLoading(false);
    setDisableRunQueryIcon(false)
  };

  const handleAccordianExapandCollape = () => {
    clearPanelContents();
    if (expanded) {
        setExpanded(false);
    } else {
        setExpanded(true);
    }
  };


  const showQueryData = () => {
    let filterObj = [selectedSourceComponentType]

    switch(selectedSourceComponentType) {
      
      case 'sye':
        filterObj = [ 'sye', 'dx', 'delta_proxy', 'emp', 'csid' ]
        break;
      case 'dx':
        filterObj = [ 'dx', 'sye']
        break;
      case 'delta_proxy':
        filterObj = [ 'delta_proxy', 'sye']
        break;
      case 'emp':
        filterObj = [ 'emp', 'sye']
        break;
      default:
        filterObj = [selectedSourceComponentType]
        break;
    }
    // let menuOptionsObj = MenuComponents.map( x => x.value).filter( y=> !filterObj.includes(y))
      return (
        MenuComponents.filter( item => !filterObj.includes(item.value)).map((option) => 
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
          ))
  }



  return (
    <React.Fragment>
      <Accordion expanded={expanded}>
        <AccordionSummary aria-controls="usecase-content" id="usecase-header">
          <AccordianHeader
            accordianSummaryHeader={"Use Case 1 "}
            accordianSummaryData={"Master Query ( Any  component to any other component) "}
            handleRunQuery={handleRunQuery}
            clearPanelContents={clearPanelContents}
            expanded={expanded}
            handleAccordianExapandCollape={handleAccordianExapandCollape}
            disableRunQueryIcon={disableRunQueryIcon}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Box
            component="form"
            sx={{
                "& .MuiTextField-root": { m: 2, width: "25ch" },
                flexGrow: 1,
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container>
            <Item xs={2}>
              <span>{" For component   "}</span>
              <TextField
                id="target_component"
                select
                // label="Select"
                defaultValue=""
                value={selectedSourceComponentType}
                variant="standard"
                helperText="Please select a component"
                onChange={handleSourceComponentTypeSelection}
                InputProps={{
                    style: {
                        border: "none",
                        padding: "0px",
                        "& fieldset": { border: "none" },
                    },
                }}
              >
                {MenuComponents.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                      {option.value}
                  </MenuItem>
                ))}
              </TextField>
              <span>{" with name /id /number  "}</span>
              <TextField
                id="standard-helperText"
                helperText={"Please enter a valid " + `${selectedSourceComponentType.split('_').join(" ")}`}
                variant="standard"
                onChange={handleSourceComponentNameSelection}
                value={selectedSourceComponentName}
                InputProps={{
                  style: {
                    border: "none",
                    padding: "0px",
                    "& fieldset": { border: "none" },
                  },
                }}
              />
            {/* </Item>
            <Item xs={2}> */}
              <span>{" Show all  "}</span>
              <TextField
                id="target_component"
                select
                // label="Select"
                defaultValue=""
                value={selectedTargetComponentType}
                variant="standard"
                helperText="Please select a target component"
                onChange={handleTargetComponentTypeSelection}
                InputProps={{
                  style: {
                    border: "none",
                    padding: "0px",
                    "& fieldset": { border: "none" },
                  },
                }}
              >
                {showQueryData()}
              </TextField>
            </Item>

            </Grid>
            <Grid container spacing={2}>
            { showAlert &&   
                  <Grid  m={2} mb={0} item xs={12} sx={{ border: "1px solid #FF9900" }}>
                      <Box m={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <ExpiringAlert 
                              alertDelay="2000"
                              key={"user-case-1"}
                              alertMessage={alertMessage}
                          />
                      </Box>
                  </Grid>
              }
              {runQuery && (
                <Grid
                  item
                  xs={8}
                  m={2}
                  sx={{
                      border: "1px solid #E8E8E8",
                  }}
                >
                  {isLoading ? (
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress />
                    </Box>
                  ) : (
                    <Box   alignItems="stretch" padding={1}
                      sx={{
                        overflowY:'auto',
                        display: "flex",
                        flexGrow: 1,
                        flexDirection:"column",
                        maxHeight:"300px"
                    }}
                    >
                      <pre>
                        {JSON.stringify(
                          {[selectedSourceComponentType] : selectedSourceComponentName, [selectedTargetComponentType] : queryData},
                          null,
                          2
                        )}
                      </pre>
                    </Box>
                  )}
                </Grid>
              )}
          </Grid>
</Box>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}

