import { API } from "aws-amplify";

const headers = {
    "Cache-Control": "no-store",
    // "Content-type": "application/json",
    "Content-type": "application/json,text/plain,text/html",
};

const runMasterQuery = async (params) => {

    const path =  "cerebroapi/v1/cerebroui";
    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getDxList function",
            })
        );
    };
    // console.log(`Path = ${process.env.REACT_APP_API_ENVIRONMENT}/${path}`); // debug aam
    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: headers,
        queryStringParameters: params,
    }).catch(handleError);
    const data = await response;
    // console.log(data); //DEBUG AAM
    return data;
    // return JSON.parse(data);
}

const getDxList = async (params) => {
    const path = "cerebro/read";
    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getDxList function",
            })
        );
    };
    // console.log(`Path = ${process.env.REACT_APP_API_ENVIRONMENT}/${path}`); // debug aam

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: headers,
        queryStringParameters: params,
    }).catch(handleError);

    const data = await response;
    // console.log(data); //DEBUG AAM
    return data;
    // return JSON.parse(data);
};

const getDxPassthroughComponentByDependentName = async (params) => {
    const path = "cerebro/read";
    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getDxPassthroughComponentByDependentName function",
            })
        );
    };
    // console.log(`Path = ${process.env.REACT_APP_API_ENVIRONMENT}/${path}`); // debug aam

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: headers,
        queryStringParameters: params,
    }).catch(handleError);

    const data = await response;
    // console.log(data); //DEBUG AAM
    return data;
    // return JSON.parse(data);
};

const getElementsMapByPropertyName = async (params) => {
    // const path = "cerebro/read";
    const path =  "cerebroapi/v1/cerebroui";
    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getDxPassthroughComponentByDependentName function",
            })
        );
    };
    // console.log(`Path = ${process.env.REACT_APP_API_ENVIRONMENT}/${path}`); // debug aam

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: headers,
        queryStringParameters: params,
    }).catch(handleError);

    const data = await response;
    // console.log(data); //DEBUG AAM
    return data;
    // return JSON.parse(data);
};

const getNeptuneGraphDbData = async (query) => {
    const path = "cerebro/read";
    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getNeptuneGraphDbData function",
            })
        );
    };
    // console.log(`Path = ${process.env.REACT_APP_API_ENVIRONMENT}/${path}`); // debug aam

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: headers,
        queryStringParameters: {
            action: "get_graph_data",
            query: query,
        },
    }).catch(handleError);

    const data = await response;
    return data;
    // return JSON.parse(data);
};


const getGraphFromInfraGroupToContributionStream = async (params) => {
    const path =  "cerebroapi/v1/cerebroui";
    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getGraphFromInfraGroupToContributionStream function",
            })
        );
    };
    // console.log(`Path = ${process.env.REACT_APP_API_ENVIRONMENT}/${path}`); // debug aam

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: headers,
        queryStringParameters: params,
    }).catch(handleError);

    const data = await response;
    // console.log(data); //DEBUG AAM
    return data;
    // return JSON.parse(data);
};

const getGraphFromContributionStreamToEnd = async (params) => {
    const path =  "cerebroapi/v1/cerebroui";
    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getGraphFromContributionStreamToEnd function",
            })
        );
    };
    // console.log(`Path = ${process.env.REACT_APP_API_ENVIRONMENT}/${path}`); // debug aam

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: headers,
        queryStringParameters: params,
    }).catch(handleError);

    const data = await response;
    // console.log(data); //DEBUG AAM
    return data;
    // return JSON.parse(data);
};

export { 
    getNeptuneGraphDbData,
    getDxPassthroughComponentByDependentName,
    getElementsMapByPropertyName,
    getDxList,
    runMasterQuery,
    getGraphFromInfraGroupToContributionStream,
    getGraphFromContributionStreamToEnd,
};
