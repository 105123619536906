import { CognitoJwtVerifier } from "aws-jwt-verify";

const prodConfig = {
  Auth: {
    region: "us-west-2",
    userPoolId: "us-west-2_kyQYLQtvH",
    userPoolWebClientId: "3o888evnsnntm2pibfq64nc21i",
    identityPoolId: "us-west-2:bbb48154-3063-4010-b3b1-54451cdc2ee6",
    mandatorySignIn: false,
    oauth: {
      domain: "dv-live-cerebro.auth.us-west-2.amazoncognito.com",
      scope: ["openid"],
      // redirectSignIn: "https://prod.d3n4okv7krdiyv.amplifyapp.com/",
      // redirectSignOut: "https://prod.d3n4okv7krdiyv.amplifyapp.com/signout/",
      redirectSignIn: "https://prod.cerebro.amazonavoc.com/",
      redirectSignOut: "https://prod.cerebro.amazonavoc.com/signout/",
      responseType: "code",
    },
  },
  API: {
    endpoints: [
      {
        name: "Gamma",
        endpoint: "https://gamma.api.amazonavoc.com/",
        region: "us-west-2",
      },
      {
        name: "Prod",
        endpoint: "https://prod.api.amazonavoc.com/",
        region: "us-west-2",
      },
    ],
  },
};

const gammaConfig = {
  Auth: {
    region: "us-west-2",
    userPoolId: "us-west-2_kyQYLQtvH",
    userPoolWebClientId: "1p3ugqn4qdt6bpnjbjs5nik3cs",
    identityPoolId: "us-west-2:bbb48154-3063-4010-b3b1-54451cdc2ee6",
    mandatorySignIn: false,
    oauth: {
      domain: "dv-live-cerebro.auth.us-west-2.amazoncognito.com",
      scope: ["openid"],
      // redirectSignIn: "https://gamma.d3n4okv7krdiyv.amplifyapp.com",
      // redirectSignOut: "https://gamma.d3n4okv7krdiyv.amplifyapp.com/signout",
      redirectSignIn: "https://gamma.cerebro.amazonavoc.com/",
      redirectSignOut: "https://gamma.cerebro.amazonavoc.com/signout/",
      responseType: "code",
    },
  },
  API: {
    endpoints: [
      {
        name: "Gamma",
        endpoint: "https://gamma.api.amazonavoc.com/",
        region: "us-west-2",
      },
      {
        name: "Prod",
        endpoint: "https://prod.api.amazonavoc.com/",
        region: "us-west-2",
      },
    ],
  },
};

//Initialize local variables
let awsConfig;
let jwtVerifier;
console.log("Node ENV", process.env.NODE_ENV);
console.log("React App ENV", process.env.REACT_APP_API_ENVIRONMENT);

//Auto Switch between different environments based on code branch and URL's
if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_API_ENVIRONMENT === "Prod"
) {
  console.log("Using Prod OAuth config and Prod API");
  awsConfig = { ...prodConfig };

  // Verifier that expects valid access tokens:
  jwtVerifier = CognitoJwtVerifier.create({
  userPoolId: "us-west-2_kyQYLQtvH",
  tokenUse: "access",
  clientId: "3o888evnsnntm2pibfq64nc21i",
});
} else if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_API_ENVIRONMENT === "Gamma"
) {
  console.log("Using Gamma OAuth config and Gamma API");
  awsConfig = { ...gammaConfig };

  // Verifier that expects valid access tokens:
  jwtVerifier = CognitoJwtVerifier.create({
  userPoolId: "us-west-2_kyQYLQtvH",
  tokenUse: "access",
  clientId: "1p3ugqn4qdt6bpnjbjs5nik3cs",
});
} else {
  console.log("Using Gamma config until Local Oauth config is setup");
  awsConfig = { ...gammaConfig };
}
// awsConfig = gammaConfig;

// awsConfig = { ...prodConfig };

// Verifier that expects valid access tokens:
// const jwtVerifier = CognitoJwtVerifier.create({
//   userPoolId: "us-west-2_kyQYLQtvH",
//   tokenUse: "access",
//   clientId: "4of782konq6ncg2o28a8nkbbu8",
// });

// export default awsConfig;
export { awsConfig, jwtVerifier };
