import * as React from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { MenuItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// Local imports
import GraphToolBar from "./GraphToolBar";
import { AccordianHeader } from "./AccordianHeader";
import { CytoGraph } from "./CytoGraph";
import { getElementsMapByPropertyName } from "../js/neptuneApiUtils";
import { GlobalContext } from "../contexts/GlobalContextStore";
import {verifyJwtToken} from "../js/miscUtils"
import {ExpiringAlert} from "./ExpiringAlert";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    textAlign: "center",
    border: "none",
    color: theme.palette.text.secondary,
}));


const layoutOptions = [
    {
        value: "bfs",
        label: "bfs",
        data: "bfsLayout",
    },
    {
        value: "dagre",
        label: "dagre",
        data: "dagreLayout",
    },
    {
        value: "coseBilkent",
        label: "coseBilkent",
        data: "coseBilkentLayout",
    },
    {
        value: "fcose",
        label: "fcose",
        data: "fcoseLayout",
    },
    {
        value: "klay",
        label: "klay",
        data: "klayLayout",
    },
];

export const UseCase4 = () => {
    const initialValue = "";
    const [expanded, setExpanded] = React.useState(false);
    const [selectedComponent, setSelectedComponent] = React.useState(initialValue);
    const [selectedLayout, setSelectedLayout] = React.useState("");
    const [runQuery, setRunQuery] = React.useState(false);
    const [storeStates, storeDispatch] = React.useContext(GlobalContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [nodesData, setNodesData]  = React.useState([]);
    const [ disableRunQueryIcon, setDisableRunQueryIcon] = React.useState(false);
    const [ stopGraphLoad, setStopGraphLoad] = React.useState(false);



    const isUniqueNode = (graphData, node) => {
       
        if (graphData.length !== 0){
            let filteredNodes = graphData.filter( r => r.data.name === node.name)
            return filteredNodes.length === 0 ? true : false
        } else {
            //  graphData empty , initial load
            return true
        }
    }


    const isUniqueEdge = (graphData, edge) => {
        if (graphData.length !== 0){
            let filteredEdges = graphData.filter( r => r.data.source === edge.source && r.data.target === edge.target)
            return filteredEdges.length === 0 ? true : false 
        } else { 
            //  graphData empty , initial load
           return true
        }
    }

     // load status bar messages
     const loadStatusBarMsg = (msg) => {
        storeDispatch({
            type: "LOAD_STATUS_BAR_MESSAGE",
            statusBarMsg: msg,
        });
    }

    const  loadPartialData =  async ( cs_node) => {

        // Set API params
        const elementsMapByPropertyNameProps = {
            query: "graph_layout_query",
            node_type: "contribution_stream",
            node_name: cs_node,
        };
        //  Extract  each contribution_stream object from  api data
        loadStatusBarMsg(`Fetching subgraphs for ${cs_node} ....`)

        try {
            getElementsMapByPropertyName(elementsMapByPropertyNameProps).then((partialData) => {
                const subData = 'response' in  partialData ? partialData['response'] : {};
                if ('nodes' in subData && subData["nodes"].length > 1) {
                    const elements2 = []
                    //  Process Nodes , eliminate duplicates
                    subData.nodes.forEach( (node) => {
                        if (isUniqueNode( storeStates.elementsMapByPropertyNameData, node)){
                            elements2.push({ data: node})
                        }
                    })
                    //  Process Edges , eliminate duplicates
                    subData.edges.forEach( (edge) => {
                        if (isUniqueEdge( storeStates.elementsMapByPropertyNameData, edge)){
                            elements2.push({ data: { source: edge.source.id, target: edge.target.id }})
                        }
                    })
        
                    storeDispatch({
                        type: "LOAD_ELEMENTS_MAP_BY_PROPERTY_NAME",
                        elementsMapByPropertyNameData: [...storeStates.elementsMapByPropertyNameData, ...elements2],
                    });
                
                    setRunQuery(true);
                } 
            })

        } catch (e) {
            console.error(e)
        }
    }

    React.useEffect(() => {
        // Handles dynamic status bar nodes/edges count.
        
        let displayNodesCount = 0
        let displayEdgesCount = 0

        try {
            displayNodesCount =  nodesData !== undefined ? nodesData.filter( r => ( r !== undefined && 'data' in r && 'source' in r.data && 'target' in r.data) === false).length :  0
            displayEdgesCount =  nodesData !== undefined ? nodesData.filter( r => ( r !== undefined && 'data' in r && 'source' in r.data && 'target' in r.data) === true).length : 0

        } catch ( e) {
            if (e instanceof TypeError) {

                displayNodesCount = 0
                displayEdgesCount = 0
            }
        }
       
        // console.log(`CONSOLE => nodes: ${displayNodesCount}, edges: ${displayEdgesCount}`)

        storeDispatch({
            type: "LOAD_STATUS_BAR_MESSAGE",
            statusBarMsg: `Graph nodes: ${displayNodesCount}, edges: ${displayEdgesCount}`,
        });
    }, [nodesData])

    React.useEffect(() => {
        // Load updated nodes/edges data to local state
        setNodesData((prevData) => [...prevData, ...storeStates.elementsMapByPropertyNameData])
    },[storeStates.elementsMapByPropertyNameData])


    React.useEffect(() => {
        if (runQuery && !stopGraphLoad) {
            const elementsMapByPropertyNameProps = {
                query: "graph_layout_query",
                node_type: "infra_group",
                node_name: selectedComponent,
            };

            // Process elementsMapByPropertyNameProps
            try {
                getElementsMapByPropertyName(elementsMapByPropertyNameProps).then((data) => {
                   
                    // Convert data to cytoScape map format
                    try {
                        data = 'response' in data ? data['response'] : [];
                        if (Object.keys(data).includes('nodes') && data["nodes"].length > 1) {
                            const elements = [
                                ...data.nodes.map((r) => ({ data: r })),
                                ...data.edges.map((r) => ({
                                    data: { source: r.source.id, target: r.target.id },
                                })),
                            ];
                            storeDispatch({
                                type: "LOAD_ELEMENTS_MAP_BY_PROPERTY_NAME",
                                elementsMapByPropertyNameData: elements,
                            });

                            //  Add partial load logic here ..............
                            let cs_nodes = 'nodes' in data ?  data.nodes.filter( r => r.label === 'contribution_stream').map( r => r.id) : []
                            // console.log("cs_nodes:", cs_nodes);
                           
                            loadStatusBarMsg(`Fetching subgraphs for ${cs_nodes.length} contribution stream nodes`)
                            setIsLoading(false);

                            // Fetch async partial data for constribution_stream subgraphs.
                            const interval = 1000;
                            cs_nodes !== undefined && cs_nodes.length > 0 && cs_nodes.forEach( (cs_node, idx, array) => {
                                try {
                                    setTimeout(() => ( async() => await loadPartialData(cs_node))(), (idx + 1) * interval);
                                } catch (err) {
                                    console.err('Error loading partial data');
                                    console.err(err)
                                }
                            });
                            
                        } else {
                            storeDispatch({
                                type: "LOAD_ELEMENTS_MAP_BY_PROPERTY_NAME",
                                elementsMapByPropertyNameData: [],
                            });
                        }
    
                        // Data received clear form contents
                        setIsLoading(false);
                        
                    } catch (e) {
                        if (e.sender !== "TypeError") {
                            console.error("data missing or event timed out")
                            setIsLoading(false);
                            setShowAlert(true)
                            setAlertMessage("Data Fetch API Timed Out")
                            throw e
                        };
                    }
                   
                });
            } catch (apiCallErr) {
                console.error(apiCallErr);
            }
        }
    }, [runQuery, stopGraphLoad]);

    const clearPanelContents = () => {
        setStopGraphLoad(true);
        setRunQuery(false);
        setIsLoading(false);
        setNodesData([]);
        storeDispatch({
            type: "LOAD_ELEMENTS_MAP_BY_PROPERTY_NAME",
            elementsMapByPropertyNameData: [],
        });
        loadStatusBarMsg("");
        setSelectedComponent(initialValue);
        setSelectedLayout("");
        setDisableRunQueryIcon(false)
    };

    const handleAccordianExapandCollape = () => {
        clearPanelContents()
        if (expanded) {
            setExpanded(false);
        } else {
            setExpanded(true);
        }
    };

    const handleLayoutSelection = (event) => {
        setSelectedLayout(event.target.value);
    };

    const handleComponentSelection = (event) => {
        setRunQuery(false);
        setIsLoading(false);
        setSelectedComponent(event.target.value);
        loadStatusBarMsg("")
        setDisableRunQueryIcon(false)
    };

    const handleRunQuery = async (event) => {
        // Check JWT validity
        // ============== dev ===================
        console.log("validating existing token expiry")
        let isSessionTokenValid = storeStates.sessionToken !== null ? await verifyJwtToken(storeStates.sessionToken) : false
        console.log(" token valid: ", isSessionTokenValid)
        //  ============ end dev =================

        if ( isSessionTokenValid === true){
            console.log("token good")
            setShowAlert(false)
            setRunQuery(selectedComponent && selectedLayout ? true : false);
            setIsLoading(true);
            setNodesData([])
            storeDispatch({
                type: "LOAD_ELEMENTS_MAP_BY_PROPERTY_NAME",
                elementsMapByPropertyNameData: [],
            });
            loadStatusBarMsg("")
            setStopGraphLoad(false)
            setDisableRunQueryIcon(selectedComponent && selectedLayout ? true : false)
        } else {
            // token invalid , logout
            console.log("token bad - logout")
            setAlertMessage("Token expired, redirecting to login page ....")
            setShowAlert(true)
            }
    };

    return (
        <React.Fragment>
            <Accordion expanded={expanded} m={2}>
                <AccordionSummary aria-controls="usecase-content" id="usecase-header">
                    <AccordianHeader
                        accordianSummaryHeader={"Use Case 4"}
                        accordianSummaryData={"Property Graph Visualization"}
                        handleRunQuery={handleRunQuery}
                        clearPanelContents={clearPanelContents}
                        expanded={expanded}
                        handleAccordianExapandCollape={handleAccordianExapandCollape}
                        disableRunQueryIcon={disableRunQueryIcon}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 2, width: "25ch" },
                            flexGrow: 1,
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container>
                            <Item xs={2}>
                                <span>{" Show me how   "}</span>

                                <TextField
                                    id="standard-helperText"
                                    helperText="Please enter valid property name (e.g. 'auicc')"
                                    variant="standard"
                                    onChange={handleComponentSelection}
                                    value={selectedComponent}
                                    InputProps={{
                                        style: {
                                            border: "none",
                                            padding: "0px",
                                            "& fieldset": { border: "none" },
                                        },
                                    }}
                                />
                                <span>{"  property is configured using       "}</span>
                                <TextField
                                    id="target_component"
                                    select
                                    // label="Select"
                                    defaultValue=""
                                    value={selectedLayout}
                                    variant="standard"
                                    helperText="Please select a layout"
                                    onChange={handleLayoutSelection}
                                    InputProps={{
                                        style: {
                                            border: "none",
                                            padding: "0px",
                                            "& fieldset": { border: "none" },
                                        },
                                    }}
                                >
                                    {layoutOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <span>{"  layout ?       "}</span>
                            </Item>
                        </Grid>
                    </Box>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 2, width: "25ch" },
                            flexGrow: 1,
                        }}
                        noValidate
                        autoComplete="off"
                    >
                          <Grid container spacing={2}>
                            { showAlert &&    
                            
                                <Grid  m={2} mb={0} item xs={12} sx={{ border: "1px solid #FF9900" }}>
                                    <Box m={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <ExpiringAlert 
                                            alertDelay="2000"
                                            key={"user-case-4"}
                                            alertMessage={alertMessage}
                                        />
                                    </Box>
                                </Grid>
                            }
                            {runQuery && (
                                <>
                                    {isLoading ? (
                                        <Grid  m={2} mb={0} item xs={12} sx={{ border: "1px solid #FF9900" }}>
                                            <Box m={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <CircularProgress />
                                            </Box>
                                        </Grid>
                                    ) : (
                                        <React.Fragment>
                                            <Grid m={2} mb={0} item xs={12} sx={{ border: "1px solid #FF9900" }}>
                                                <GraphToolBar />
                                            </Grid>
                                            <Grid m={2} mt={0} item xs={12} sx={{ border: "1px solid #FF9900" }}>
                                                <CytoGraph
                                                    layout={selectedLayout || 'bfs'}
                                                    elements={nodesData || []}
                                                    
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
};
