import React from "react";
import CytoscapeComponent from "react-cytoscapejs";
import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre";
import coseBilkent from "cytoscape-cose-bilkent";
import fcose from "cytoscape-fcose";
import klay from "cytoscape-klay";
import popper from "cytoscape-popper";
import panzoom from "cytoscape-panzoom";

import { bfsLayout, klayLayout, fCoseLayout, coseBilkentLayout, dagreLayout } from "../js/graphLayoutConfigs";
import { nodeColorObject } from "../js/miscUtils";

import "../css/popper.css";
import "../css/panzoom.css";
import "../css/graphStyles.css";

cytoscape.use(dagre);
cytoscape.use(coseBilkent);
cytoscape.use(fcose);
cytoscape.use(klay);
cytoscape.use(popper);
cytoscape.use(panzoom);
// panzoom(cytoscape);

export const CytoGraph = (props) => {
    const graphWidth = "100%";
    const graphHeight = "800px";
    // console.log(props)

    React.useEffect(() => {
        // Component mounted
        return () => {
        // Component unmounted
        return (<CytoscapeComponent 
            id={"cyContainer"}
            elements={[]}
            style={{ width: graphWidth, height: graphHeight }}
            layout={'breadthfirst'}
        />)
        
        };
    }, []);

    const layoutMap = {
        bfs: bfsLayout,
        dagre: dagreLayout,
        coseBilkent: coseBilkentLayout,
        fcose: fCoseLayout,
        klay: klayLayout,
    };

    const setNodeColor = (node) => {
        const nodeLabel = node.data("label");
        if (nodeColorObject[nodeLabel] !== undefined) {
            return nodeColorObject[nodeLabel];
        } else {
            return nodeColorObject.default;
        }
    };

    const styleSheet = [
        {
            selector: "node",
            label: (node) => node("label"),
            style: {
                backgroundColor: (node) => setNodeColor(node),
                width: 30,
                height: 30,
                label: "data(label)",
            },
        },
        {
            selector: "node.hover",
            style: {
                "border-color": "#000000",
                "text-background-color": "#eeeeee",
                "text-background-opacity": 1,
            },
        },
        {
            selector: "node:selected",
            style: {
                "border-width": "6px",
                "border-color": "#AAD8FF",
                "border-opacity": "0.5",
                // "background-color": "#77828C",
                width: 100,
                height: 100,
                //text props
                // "text-outline-color": "#77828C",
                // "text-outline-width": 8,
            },
        },

        {
            selector: "node[type='service']",
            style: {
                shape: "rectangle",
                "background-color": "lightgrey",
            },
        },
        {
            selector: "edge",
            style: {
                width: 1,
                // "line-color": "#6774cb",
                "line-color": "#6774cb",
                "target-arrow-color": "#6774cb",
                "target-arrow-shape": "triangle",
                "curve-style": "bezier",
            },
        },
    ];

    // the default values of each option are outlined below:
    var panZoomDefaults = {
        zoomFactor: 0.05, // zoom factor per zoom tick
        zoomDelay: 45, // how many ms between zoom ticks
        minZoom: 0.1, // min zoom level
        maxZoom: 10, // max zoom level
        fitPadding: 50, // padding when fitting
        panSpeed: 10, // how many ms in between pan ticks
        panDistance: 10, // max pan distance per tick
        panDragAreaSize: 75, // the length of the pan drag box in which the vector for panning is calculated (bigger = finer control of pan speed and direction)
        panMinPercentSpeed: 0.25, // the slowest speed we can pan by (as a percent of panSpeed)
        panInactiveArea: 8, // radius of inactive area in pan drag box
        panIndicatorMinOpacity: 0.5, // min opacity of pan indicator (the draggable nib); scales from this to 1.0
        zoomOnly: false, // a minimal version of the ui only with zooming (useful on systems with bad mousewheel resolution)
        fitSelector: undefined, // selector of elements to fit
        animateOnFit: function () {
            // whether to animate on fit
            return false;
        },
        fitAnimationDuration: 1000, // duration of animation on fit

        // icon class names
        sliderHandleIcon: "fa fa-minus",
        zoomInIcon: "fa fa-plus",
        zoomOutIcon: "fa fa-minus",
        resetIcon: "fa fa-expand",
    };

    React.useEffect(() => {
        // Component mounted
        return () => {
        // Component unmounted
        // Return blank component to parent
        return (<CytoscapeComponent 
            id={"cyContainer"}
            elements={[]}
            style={{ width: graphWidth, height: graphHeight }}
            layout={'breadthfirst'}
        />)
        };
    }, []);

    return (
        <CytoscapeComponent
            id={"cyContainer"}
            elements={props.elements}
            style={{ width: graphWidth, height: graphHeight }}
            zoomingEnabled={true}
            maxZoom={3}
            minZoom={0.1}
            autounselectify={false}
            boxSelectionEnabled={true}
            layout={layoutMap[props.layout] || 'breadthfirst'}
            stylesheet={styleSheet}
            cy={(cy) => {
                cy.panzoom(panZoomDefaults);
                // console.log(cy);

                cy.ready(function () {
                    // var layout = cy.layout({ name: 'breadthfirst', directed: true, padding: 2 });
                    var layout = cy.layout(layoutMap[props.layout] || 'breadthfirst');
                    layout.run();
                });

                // cy.on("tap", "node", (evt) => {
                //     var node = evt.target;
                //     console.log("EVT: ", evt);
                //     console.log("TARGET: ", node.data());
                //     console.log("TARGET TYPE: ", typeof node[0]);
                // });

                // cy.on("click", "node", function (event) {
                //     var node = event.target;
                //     makePopper(node, cy);
                // });

                // // Working: Commented to test out custom right click menu
                cy.nodes().unbind("cxttap");
                cy.nodes().bind("cxttap", (event) => {
                    // console.log("right click detected:");

                    if (event.target.popper) {
                        event.target.popperRefObj.state.elements.popper.remove();
                        event.target.popperRefObj.destroy();
                    }
                    // console.log(event);

                    event.target.popperRefObj = event.target.popper({
                        content: () => {
                            let content = document.createElement("pre");
                            content.classList.add("popper-div");
                            content.innerHTML = JSON.stringify(event.target.data(), null, 2);
                            document.body.appendChild(content);
                            return content;
                        },
                    });
                });

                cy.nodes().unbind("mouseover");
                cy.nodes().bind("mouseover", (event) => {
                    event.target.popperRefObj = event.target.popper({
                        content: () => {
                            let content = document.createElement("div");

                            content.classList.add("popper-div");

                            content.innerHTML = event.target.id();

                            document.body.appendChild(content);
                            return content;
                        },
                    });
                });

                cy.nodes().unbind("mouseout");
                cy.nodes().bind("mouseout", (event) => {
                    if (event.target.popper) {
                        event.target.popperRefObj.state.elements.popper.remove();
                        event.target.popperRefObj.destroy();
                    }
                });
            }}
        />
    );
};
