import * as React from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Local imports
import { AccordianHeader } from "./AccordianHeader";
import { GlobalContext } from "../contexts/GlobalContextStore";
import { getDxPassthroughComponentByDependentName } from "../js/neptuneApiUtils";
import {verifyJwtToken} from "../js/miscUtils"
import {ExpiringAlert} from "./ExpiringAlert";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    textAlign: "center",
    border: "none",
    color: theme.palette.text.secondary,
}));

const components = [
    {
        value: "infra_group",
        label: "infra_group",
    },
    {
        value: "cs_group",
        label: "cs_group",
    },
    {
        value: "contribution_stream",
        label: "contribution_stream",
    },
    {
        value: "feed",
        label: "feed",
    },

    {
        value: "packager_stream",
        label: "packager_stream",
    },
    {
        value: "hw_encoder",
        label: "hw_encoder",
    },
    {
        value: "job",
        label: "job",
    },
    {
        value: "csid",
        label: "csid",
    },
];

export const UseCase3 = () => {
    const initialValue = "";
    const [expanded, setExpanded] = React.useState(false);
    const [selectedComponent, setSelectedComponent] = React.useState(initialValue);
    const [selectedDX, setSelectedDX] = React.useState(initialValue);
    const [runQuery, setRunQuery] = React.useState(false);
    const [storeStates, storeDispatch] = React.useContext(GlobalContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [ disableRunQueryIcon, setDisableRunQueryIcon] = React.useState(false);

    // load status bar messages
    const loadStatusBarMsg = (msg) => {
        storeDispatch({
            type: "LOAD_STATUS_BAR_MESSAGE",
            statusBarMsg: msg,
        });
    }

    React.useEffect(() => {
        if (runQuery) {
            const passthroughComponentsByDependentNameProps = {
                action: "get_dx_passthrough_components_by_dependent_name",
                dx_name: selectedDX,
                dependent_component: selectedComponent,
                // dx_name: "EDM_ATL-PDX",
                // dependent_component: "feed",
            };

            // Process getDxPassthroughComponentByDependentName
            try {
                getDxPassthroughComponentByDependentName(passthroughComponentsByDependentNameProps).then((data) => {
                    console.log("getDxPassthroughComponentByDependentName result:");
                    console.log(data);
                    storeDispatch({
                        type: "LOAD_DX_PASSTHROUGH_COMPONENTS_BY_DEPENDENT_NAME",
                        dxPassthroughComponentsByDependentNameData: data,
                    });
                    // Data received clear form contents
                    setIsLoading(false);
                    // setSelectedComponent(initialValue);
                    // setSelectedDX(initialValue);
                });
            } catch (apiCallErr) {
                // console.log(apiCallErr);
                console.error("data missing or event timed out")
                setIsLoading(false);
                setShowAlert(true)
                setAlertMessage("Data Fetch API Timed Out")
                throw apiCallErr
            }
        }
    }, [runQuery]);

    React.useEffect(() => {
        console.log(storeStates.dxListData);
    }, [storeStates.dxListData]);

    const clearPanelContents = () => {
        setSelectedComponent(initialValue);
        setSelectedDX(initialValue);
        setRunQuery(false);
        setIsLoading(false);
        loadStatusBarMsg("")
        setDisableRunQueryIcon(false)
    };

    const handleAccordianExapandCollape = () => {
        clearPanelContents();
        if (expanded) {
            setExpanded(false);
        } else {
            setExpanded(true);
        }
    };

    const handleComponentSelection = (event) => {
        setRunQuery(false);
        console.log("Selected Component:", event.target.value); //debug aam
        setSelectedComponent(event.target.value);
        loadStatusBarMsg("")
        setDisableRunQueryIcon(false)
    };

    const handleDxListComponentSelection = (event) => {
        setRunQuery(false);
        console.log("Selected DX List Component:", event.target.value); //debug aam
        setSelectedDX(event.target.value);
        loadStatusBarMsg("")
        setDisableRunQueryIcon(false)
    };

    const handleDXSelection = (event) => {
        setRunQuery(false);
        console.log("Selected DX:", event.target.value); //debug aam
        setSelectedDX(event.target.value);
    };

    const handleRunQuery = async (event) => {
        // Check JWT validity
        console.log("validating existing token expiry")
        let isSessionTokenValid = storeStates.sessionToken !== null ? await verifyJwtToken(storeStates.sessionToken) : false
        console.log(" token valid: ", isSessionTokenValid)
        if ( isSessionTokenValid === true){
            console.log("token good")
            setShowAlert(false)
            setRunQuery(selectedComponent && selectedDX ? true : false);
            setIsLoading(true);
            loadStatusBarMsg("")
            setDisableRunQueryIcon(selectedComponent && selectedDX ? true : false)
        } else {
            // token invalid , logout
            console.log("token bad - logout")
            setAlertMessage("Token expired, redirecting to login page ....")
            setShowAlert(true)
            }
    };

    return (
        <React.Fragment>
            <Accordion expanded={expanded}>
                <AccordionSummary aria-controls="usecase-content" id="usecase-header">
                    <AccordianHeader
                        accordianSummaryHeader={"Use Case 3"}
                        accordianSummaryData={"DX Related Queries"}
                        handleRunQuery={handleRunQuery}
                        clearPanelContents={clearPanelContents}
                        expanded={expanded}
                        handleAccordianExapandCollape={handleAccordianExapandCollape}
                        disableRunQueryIcon={disableRunQueryIcon}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 2, width: "25ch" },
                            flexGrow: 1,
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container>
                            <Item xs={2}>
                                <span>{" Show me all the   "}</span>
                                <TextField
                                    id="target_component"
                                    select
                                    // label="Select"
                                    defaultValue=""
                                    value={selectedComponent}
                                    variant="standard"
                                    helperText="Please select a component"
                                    onChange={handleComponentSelection}
                                    InputProps={{
                                        style: {
                                            border: "none",
                                            padding: "0px",
                                            "& fieldset": { border: "none" },
                                        },
                                    }}
                                >
                                    {components.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <span>{"  passing through DX      "}</span>
                                {/* Switch between dynamic dx values & user defined input  */}
                                {"dx" in storeStates.dxListData && storeStates.dxListData.dx.length > 0 ? (
                                    <TextField
                                        id="dx_list"
                                        select
                                        // label="Select"
                                        defaultValue=""
                                        value={selectedDX}
                                        variant="standard"
                                        helperText="Please select DX"
                                        onChange={handleDxListComponentSelection}
                                        InputProps={{
                                            style: {
                                                border: "none",
                                                padding: "0px",
                                                "& fieldset": { border: "none" },
                                            },
                                        }}
                                    >
                                        {storeStates.dxListData.dx.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : (
                                    <TextField
                                        id="standard-helperText"
                                        // label="Helper text"
                                        // defaultValue="Your DX ID"
                                        helperText="Please enter valid DX ID (e.g. EDM_ATL-PDX)"
                                        variant="standard"
                                        onChange={handleDXSelection}
                                        value={selectedDX}
                                        InputProps={{
                                            style: {
                                                border: "none",
                                                padding: "0px",
                                                "& fieldset": { border: "none" },
                                            },
                                        }}
                                    />
                                )}
                                {"   "} ?
                            </Item>
                        </Grid>

                        <Grid container spacing={2}>
                        { showAlert &&    
                            
                            <Grid  m={2} mb={0} item xs={12} sx={{ border: "1px solid #FF9900" }}>
                                <Box m={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <ExpiringAlert 
                                        alertDelay="2000"
                                        key={"user-case-3"}
                                        alertMessage={alertMessage}
                                    />
                                </Box>
                            </Grid>
                        }
                            {runQuery && (
                                <Grid
                                    item
                                    xs={8}
                                    m={2}
                                    sx={{
                                        border: "1px solid #E8E8E8",
                                    }}
                                >
                                    {isLoading ? (
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <Box   alignItems="stretch" padding={1}
                                            sx={{
                                                overflowY:'auto',
                                                display: "flex",
                                                flexGrow: 1,
                                                flexDirection:"column",
                                                maxHeight:"300px"
                                            }}
                                        >
                                            <pre>
                                                {JSON.stringify(
                                                    storeStates.dxPassthroughComponentsByDependentNameData,
                                                    null,
                                                    2
                                                )}
                                            </pre>
                                        </Box>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
};