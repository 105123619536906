import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import Grid from "@mui/material/Grid";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';


export const AccordianHeader = (props) => {

    React.useEffect(() => {
        // Reload component on props change
    }, [props])
    

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item p={1}>
                        <Typography>{props.accordianSummaryHeader}</Typography>
                    </Grid>
                    <Grid item p={1}>
                        <Typography sx={{ color: "text.secondary" }}>{props.accordianSummaryData}</Typography>
                    </Grid>
                    <Grid item p={1}>
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical"  />}
                            spacing={2}
                        >

                            <Tooltip title={props.disableRunQueryIcon ? "Clear Contents to Enable Run Query" : "Run Query"} TransitionComponent={Fade} TransitionProps={{ timeout: 300 }} arrow>
                                <Box>
                                
                                    <KeyboardReturnIcon 
                                        size="large"
                                        disabled={props.disableRunQueryIcon}
                                        onClick={props.handleRunQuery}
                                        sx={{ 
                                            color: props.disableRunQueryIcon ? "#dadada" : "teal",
                                            pointerEvents: props.disableRunQueryIcon ? "none" : "auto",
                                        }}
                                    />
                                </Box>
                            </Tooltip>

                            {/* ------------------------------ */}
                            
                            <Tooltip
                            title="Clear Contents"
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 300 }}
                            arrow
                        >
                            <Box>
                            
                                <ClearOutlinedIcon 
                                    size="large"
                                    color="red"
                                    aria-label="usecase1"
                                    onClick={props.clearPanelContents}
                                    sx={{ color: "red" }} 
                                />
                           
                            </Box>
                        </Tooltip> 

                        {/* ------------------------------ */}

                        <Tooltip
                            title={!props.expanded ? "Expand Panel" : "Collapse Panel"}
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 300 }}
                            arrow
                        >
                            <Box>
                                    {
                                        !props.expanded ? 
                                            <ExpandMoreIcon onClick={props.handleAccordianExapandCollape} /> 
                                        : 
                                            <ExpandLessIcon onClick={props.handleAccordianExapandCollape}
                                    />}
                               
                            </Box>
                        </Tooltip>

                         {/* ------------------------------ */}

                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
};
